import {
  CHANGE_FIELD_COMPETITORS,
  CHANGE_FILTER_REVENUE_LEAKAGE,
  CHANGE_ACTIVE_CATEGORY_REVENUE_LEAKAGE,
  FETCH_INSIGHTS_REQUEST,
  FETCH_INSIGHTS_SUCCESS,
  FETCH_INSIGHTS_FAILURE,
  FETCH_INSIGHTS_CANCEL,
  CHANGE_ACTIVE_CATEGORY_LEVEL_ONE,
  FETCH_INSIGHTS_LEVEL_ONE_REQUEST,
  FETCH_INSIGHTS_LEVEL_ONE_SUCCESS,
  CLEAR_LEVEL_ONE,
  FETCH_INSIGHTS_LEVEL_TWO_REQUEST,
  FETCH_INSIGHTS_LEVEL_TWO_SUCCESS,
  CLEAR_LEVEL_TWO,
  CHANGE_VISIBLE_COMPETITORS,
  CHANGE_SKUS_SORTING,
  CHANGE_SKUS_OFFSET,
  CHANGE_SKUS_SELECTED_COLUMNS,
  CHANGE_SKUS_SETTINGS,
  FETCH_SKUS_REQUEST,
  FETCH_SKUS_SUCCESS,
  FETCH_SKUS_FAILURE,
  FETCH_SKUS_CANCEL,
  FETCH_DATE_RANGE_SUCCESS,
  FETCH_MORE_SKUS_REQUEST,
  FETCH_MORE_SKUS_SUCCESS,
  FETCH_MORE_SKUS_FAILURE,
  FETCH_MORE_SKUS_CANCEL,
  CHANGE_SKUS_DETAIL_ID,
  CHANGE_PRICE_CHART,
  CHANGE_DAILY_VIEWS_CHART,
  SORT_DAILY_VIEWS_CHART,
  CHANGE_DAILY_SALES_CHART,
  SORT_DAILY_SALES_CHART,
  SORT_PRICE_CHART,
  CHANGE_AVAILABILITY_CHART,
  SORT_AVAILABILITY_CHART,
  CLEAR_SKUS_ITEMS,
  CLEAR_INSIGHTS,
  UPDATE_CURRENT_VIEW_CONFIG_QUERY,
  UPDATE_VIEW_TYPE,
  SHOW_SIDEBAR_FILTER,
  FETCH_BRANDS_SUCCESS,
  FETCH_BRANDS_FAILURE,
  UPDATE_FILTERS_SELECTED,
  CLEAR_FILTERS_SELECTED,
  CHANGE_SKUS_CHARTS_SETTINGS,
} from "./constants";

import {
  SET_CURRENT_VIEW_IS_UPDATED,
  SET_CURRENT_VIEW_CONFIG,
  UPDATE_CURRENT_VIEW_CONFIG,
  FETCH_VIEWS_DEFINITIONS_REQUEST,
  FETCH_VIEWS_DEFINITIONS_FAILURE,
  FETCH_VIEWS_DEFINITIONS_SUCCESS,
  FETCH_VIEWS_DEFINITIONS_CANCEL,
  FETCH_FILTER_DEFINITIONS_REQUEST,
  FETCH_FILTER_DEFINITIONS_FAILURE,
  FETCH_FILTER_DEFINITIONS_SUCCESS,
  FETCH_FILTER_DEFINITIONS_CANCEL,
  SAVE_INSIGHTS_VIEW_CONFIG_REQUEST,
  SAVE_INSIGHTS_VIEW_CONFIG_FAILURE,
  SAVE_INSIGHTS_VIEW_CONFIG_SUCCESS,
  SAVE_INSIGHTS_VIEW_CONFIG_CANCEL,
  FETCH_INSIGHTS_VIEWS_CONFIGS_REQUEST,
  FETCH_INSIGHTS_VIEWS_CONFIGS_FAILURE,
  FETCH_INSIGHTS_VIEWS_CONFIGS_SUCCESS,
  FETCH_INSIGHTS_VIEWS_CONFIGS_CANCEL,
  UPDATE_INSIGHTS_VIEW_CONFIG_REQUEST,
  UPDATE_INSIGHTS_VIEW_CONFIG_FAILURE,
  UPDATE_INSIGHTS_VIEW_CONFIG_SUCCESS,
  UPDATE_INSIGHTS_VIEW_CONFIG_CANCEL,
  DELETE_INSIGHTS_VIEW_CONFIG_REQUEST,
  DELETE_INSIGHTS_VIEW_CONFIG_FAILURE,
  DELETE_INSIGHTS_VIEW_CONFIG_SUCCESS,
  DELETE_INSIGHTS_VIEW_CONFIG_CANCEL,
  EXPORT_INSIGHTS_VIEW_REQUEST,
  EXPORT_INSIGHTS_VIEW_FAILURE,
  EXPORT_INSIGHTS_VIEW_SUCCESS,
  EXPORT_INSIGHTS_VIEW_CANCEL,
  EXPORT_INSIGHTS_VIEW_FAILURE_CLOSE_MESSAGE,
  REMOVE_EXPORTED_INSIGHTS_VIEW_DATA,
  SET_INITIAL_VIEW_LOADED,
} from "./insights-data-controls/constants";

import {
  FETCH_INSIGHTS_AI_REQUEST,
  FETCH_INSIGHTS_AI_SUCCESS,
  FETCH_INSIGHTS_AI_CANCEL,
  FETCH_INSIGHTS_AI_ERROR,
  SHOW_INSIGHTS_AI_SIDEBAR,
} from "./insights-ai-sidebar/constants";

import createFilterValue from "../../utils/skus/createFilterValue";

import { insightsSkusLimit } from "../../config";

import { combineReducers } from "redux";
import MbFilterRowReducer from "../../mb_components/mb-filter-row/reducer";
import { deepClone, sortObject } from "../../utils/other/utilities";

const defaultViewConfig = {
  id: null,
  name: "New View",
  query: {
    sortField: 0,
    sortOrder: 1,
    selectedColumns: [],
    settings: {},
    limit: insightsSkusLimit,
    offset: 0,
    queryFrequency: 0,
    productAttributes: [],
  },
  notificationFrequency: 1,
  emails: null,
  isUpdated: false,
  exportedCsvContent: null,
  validEmail: false,
};

const initialState = {
  competitor: {
    name: "",
    rootDomain: "",
  },
  brands: [],
  categorySelected: "",
  filterOptions: [
    {
      id: 1,
      codedName: "All",
      name: "All",
    },
    {
      id: 2,
      codedName: "MatchedSKUs",
      name: "Existing Items Only",
    },
    {
      id: 3,
      codedName: "UnmatchedSKUs",
      name: "Missing Items Only",
    },
  ],
  revenueSortValue: "Ascending",
  byCategories: [],
  byCompetitors: [],
  activeCategory: "",
  topCategoryLevel1: {
    byCategories: [],
    byCompetitors: [],
    activeCategory: "",
  },
  topCategoryLevel2: {
    byCategories: [],
    byCompetitors: [],
  },
  competitorsConfig: {
    isVisibleTooltip: true,
    isVisibleCompetitors: false,
  },
  loadingRevenue: true,
  loadingRevenueItem: false,
  loadingRevenueBlocked: false,
  loading: false,
  dateRange: {
    startDate: "",
    endDate: "",
  },
  skus: {
    productList: [],
    topCategoryLevel1ProductList: [],
    topCategoryLevel2ProductList: [],
    productDetails: {
      isVisibleProductDetails: false,
      activeId: null,
    },
    priceChart: {
      data: {
        labels: [],
        datasets: [],
      },
      priceSelected: "Last 30 days",
    },
    dailyViewsChart: {
      data: {
        labels: [],
        datasets: [],
      },
      dailyViewsSelected: "Last 30 days",
    },
    dailySalesChart: {
      data: {
        labels: [],
        datasets: [],
      },
      dailySalesSelected: "Last 30 days",
    },
    availabilityChart: {
      data: {
        xLabels: [],
        yLabels: [],
        data: [],
      },
      availabilitySelected: "Last 30 days",
    },
    hasMore: true,
    loading: true,
    loadingMore: false,
  },
  insightsFilterShowSidebar: true,
  insightsAiShowSidebar: false,
  viewsConfigsList: null,
  viewDefinitions: {
    filterDefinitionsHaveLoaded: false,
    filterDefinitions: [],
    viewMetadata: {
      domainsMetadata: {},
      lastProcessedDay: "",
    },
  },
  currentViewConfig: defaultViewConfig,
  currentViewConfigUpdated: false,
  loadedInitialView: false,
  viewTypeData: { name: "insights", id: 0 },
  isWaitingForExportToFinish: false,
  showErrorMessage: false,
  insightsAiData: {},
  marketTrendsConfigs: [],
  clearFiltersSelected: false,
  updateFiltersSelected: false,
};

const insightsBaseReducer = (state = initialState, action) => {
  switch (action.type) {
    // competitors
    case CHANGE_FIELD_COMPETITORS: {
      const { name, rootDomain } = action.payload;

      return {
        ...state,
        competitor: {
          name,
          rootDomain,
        },
        brands: [],
      };
    }

    // filter
    case CHANGE_FILTER_REVENUE_LEAKAGE: {
      const { revenueSortValue } = action.payload;

      return {
        ...state,
        revenueSortValue,
      };
    }

    // change category
    case CHANGE_ACTIVE_CATEGORY_REVENUE_LEAKAGE: {
      const { activeCategory } = action.payload;

      return {
        ...state,
        activeCategory,
      };
    }

    // insights
    case FETCH_INSIGHTS_REQUEST: {
      return {
        ...state,
        loadingRevenue: true,
        loading: true,
        activeCategory: "",
        topCategoryLevel1: {
          activeCategory: "",
        },
        topCategoryLevel2: {
          activeCategory: "",
        },
      };
    }

    case FETCH_INSIGHTS_SUCCESS: {
      const { totalSum, subNodesInfo } = action.payload;

      return {
        ...state,
        totalSum,
        subNodesInfo,
        loadingRevenue: false,
        loading: false,
      };
    }

    case FETCH_INSIGHTS_FAILURE: {
      return {
        ...state,
        loadingRevenue: false,
        loading: false,
      };
    }

    case FETCH_INSIGHTS_CANCEL: {
      return {
        ...state,
        loadingRevenue: true,
        loading: true,
      };
    }

    // change category level one
    case CHANGE_ACTIVE_CATEGORY_LEVEL_ONE: {
      const { activeCategory } = action.payload;

      return {
        ...state,
        topCategoryLevel1: {
          ...state.topCategoryLevel1,
          activeCategory,
        },
      };
    }

    // insights level one
    case FETCH_INSIGHTS_LEVEL_ONE_REQUEST: {
      return {
        ...state,
        topCategoryLevel1: {
          activeCategory: "",
        },
        loadingRevenueItem: true,
        loadingRevenueBlocked: true,
        loading: true,
      };
    }

    case FETCH_INSIGHTS_LEVEL_ONE_SUCCESS: {
      const { subNodesInfo } = action.payload;
      return {
        ...state,
        topCategoryLevel1: {
          ...state.topCategoryLevel1,
          totalSum: 0,
          subNodesInfo,
        },
        loadingRevenueItem: false,
        loadingRevenueBlocked: false,
        loading: false,
      };
    }

    case CLEAR_LEVEL_ONE: {
      let productList = [...state.skus.productList];
      productList = productList.slice(0, insightsSkusLimit);

      return {
        ...state,
        activeCategory: "",
        topCategoryLevel1: {
          activeCategory: "",
        },
        topCategoryLevel2: {},
        skus: {
          ...state.skus,
          productList,
          topCategoryLevel1ProductList: [],
          topCategoryLevel2ProductList: [],
          hasMore: true,
        },
        currentViewConfig: {
          ...state.currentViewConfig,
          query: {
            ...state.currentViewConfig.query,
            limit: insightsSkusLimit,
            offset: 0,
          },
        },
      };
    }

    // insights level two
    case FETCH_INSIGHTS_LEVEL_TWO_REQUEST: {
      return {
        ...state,
        topCategoryLevel2: {},
        loadingRevenueBlocked: true,
        loading: true,
      };
    }

    case FETCH_INSIGHTS_LEVEL_TWO_SUCCESS: {
      const { subNodesInfo } = action.payload;
      return {
        ...state,
        topCategoryLevel2: {
          totalSum: 0,
          subNodesInfo,
        },
        loading: false,
        loadingRevenueBlocked: false,
      };
    }

    case CLEAR_LEVEL_TWO: {
      let productList = [...state.skus.productList];
      let topCategoryLevel1ProductList = [
        ...state.skus.topCategoryLevel1ProductList,
      ];

      productList = productList.slice(0, insightsSkusLimit);
      topCategoryLevel1ProductList = topCategoryLevel1ProductList.slice(
        0,
        insightsSkusLimit
      );

      return {
        ...state,
        topCategoryLevel1: {
          ...state.topCategoryLevel1,
          activeCategory: "",
        },
        topCategoryLevel2: {},
        skus: {
          ...state.skus,
          productList,
          topCategoryLevel1ProductList,
          topCategoryLevel2ProductList: [],
          hasMore: true,
        },
        currentViewConfig: {
          ...state.currentViewConfig,
          query: {
            ...state.currentViewConfig.query,
            limit: insightsSkusLimit,
            offset: 0,
          },
        },
      };
    }

    // visible competitors block
    case CHANGE_VISIBLE_COMPETITORS: {
      return {
        ...state,
        competitorsConfig: {
          isVisibleTooltip: false,
          isVisibleCompetitors: action.payload,
        },
      };
    }

    /* SKUS */

    //skus sorting
    case CHANGE_SKUS_SORTING: {
      const { data } = action.payload;

      return {
        ...state,
        currentViewConfig: {
          ...state.currentViewConfig,
          query: {
            ...state.currentViewConfig.query,
            sortField:
              data.sortField !== undefined
                ? data.sortField
                : state.currentViewConfig.query.sortField,
            sortOrder:
              data.sortOrder !== undefined
                ? data.sortOrder
                : state.currentViewConfig.query.sortOrder,
          },
        },
      };
    }

    case CHANGE_SKUS_OFFSET: {
      const value = action.payload;

      return {
        ...state,
        currentViewConfig: {
          ...state.currentViewConfig,
          query: {
            ...state.currentViewConfig.query,
            offset: value,
          },
        },
      };
    }

    // selected columns
    case CHANGE_SKUS_SELECTED_COLUMNS: {
      const { value } = action.payload;

      return {
        ...state,
        currentViewConfig: {
          ...state.currentViewConfig,
          query: {
            ...state.currentViewConfig.query,
            selectedColumns: value,
          },
        },
      };
    }

    // change settings
    case CHANGE_SKUS_SETTINGS: {
      const value = action.payload;
      const closeInsightsAiSidebar = action.closeInsightsAiSidebar;

      return {
        ...state,
        insightsAiData: closeInsightsAiSidebar ? {} : state.insightsAiData,
        insightsAiShowSidebar: closeInsightsAiSidebar
          ? false
          : state.insightsAiShowSidebar,
        currentViewConfig: {
          ...state.currentViewConfig,
          query: {
            ...state.currentViewConfig.query,
            settings: {
              ...state.currentViewConfig.query.settings,
              ...value,
            },
          },
        },
      };
    }

    // change charts settings
    case CHANGE_SKUS_CHARTS_SETTINGS: {
      const value = action.payload;

      return {
        ...state,
        currentViewConfig: {
          ...state.currentViewConfig,
          query: {
            ...state.currentViewConfig.query,
            settings: {
              ...state.currentViewConfig.query.settings,
              charts: {
                ...state.currentViewConfig.query.settings.charts,
                ...value,
              },
            },
          },
        },
      };
    }

    // skus
    case FETCH_SKUS_REQUEST: {
      const { field } = action.payload;

      return {
        ...state,
        skus: {
          ...state.skus,
          [field]: [],
          productDetails: {
            isVisibleProductDetails: false,
            activeId: "",
          },
          priceChart: {
            priceSelected: "Last 30 days",
            data: {
              labels: [],
              datasets: [],
            },
          },
          dailyViewsChart: {
            dailyViewsSelected: "Last 30 days",
            data: {
              labels: [],
              datasets: [],
            },
          },
          availabilityChart: {
            availabilitySelected: "Last 30 days",
            data: {
              xLabels: [],
              yLabels: [],
              data: [],
            },
          },
          dailySalesChart: {
            dailySalesSelected: "Last 30 days",
            data: {
              labels: [],
              datasets: [],
            },
          },
          hasMore: true,
          loading: true,
        },
      };
    }

    case FETCH_SKUS_SUCCESS: {
      const { field, data } = action.payload;

      return {
        ...state,
        skus: {
          ...state.skus,
          [field]: data,
          loading: false,
        },
        currentViewConfig: {
          ...state.currentViewConfig,
          query: {
            ...state.currentViewConfig.query,
            limit: insightsSkusLimit,
            offset: 0,
          },
        },
      };
    }

    case FETCH_SKUS_FAILURE: {
      return {
        ...state,
        skus: {
          frequency: "",
          productList: [],
          topCategoryLevel1ProductList: [],
          topCategoryLevel2ProductList: [],
          productDetails: {
            isVisibleProductDetails: false,
            activeId: null,
          },
          priceChart: {
            priceSelected: "Last 30 days",
            data: {
              labels: [],
              datasets: [],
            },
          },
          dailyViewsChart: {
            dailyViewsSelected: "Last 30 days",
            data: {
              labels: [],
              datasets: [],
            },
          },
          availabilityChart: {
            availabilitySelected: "Last 30 days",
            data: {
              xLabels: [],
              yLabels: [],
              data: [],
            },
          },
          dailySalesChart: {
            dailySalesSelected: "Last 30 days",
            data: {
              labels: [],
              datasets: [],
            },
          },
          hasMore: true,
          loading: false,
          loadingMore: false,
        },
        currentViewConfig: {
          ...state.currentViewConfig,
          query: {
            ...state.currentViewConfig.query,
            limit: insightsSkusLimit,
            offset: 0,
          },
        },
      };
    }

    case FETCH_SKUS_CANCEL: {
      return {
        ...state,
        skus: {
          ...state.skus,
          loading: true,
          hasMore: true,
        },
      };
    }

    case FETCH_DATE_RANGE_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        dateRange: {
          startDate: data.startDate,
          endDate: data.endDate,
        },
      };
    }

    // skus details
    case CHANGE_SKUS_DETAIL_ID: {
      const { isVisibleProductDetails, activeId } = action.payload;

      return {
        ...state,
        skus: {
          ...state.skus,
          productDetails: {
            isVisibleProductDetails,
            activeId,
          },
        },
      };
    }

    //price chart
    case CHANGE_PRICE_CHART: {
      const { data } = action.payload;

      return {
        ...state,
        skus: {
          ...state.skus,
          priceChart: {
            ...state.skus.priceChart,
            data,
          },
        },
      };
    }

    case SORT_PRICE_CHART: {
      const { priceSelected, data } = action.payload;

      return {
        ...state,
        skus: {
          ...state.skus,
          priceChart: {
            data,
            priceSelected,
          },
        },
      };
    }

    //price chart
    case CHANGE_DAILY_VIEWS_CHART: {
      const { data } = action.payload;

      return {
        ...state,
        skus: {
          ...state.skus,
          dailyViewsChart: {
            ...state.skus.dailyViewsChart,
            data,
          },
        },
      };
    }

    case SORT_DAILY_VIEWS_CHART: {
      const { dailyViewsSelected, data } = action.payload;

      return {
        ...state,
        skus: {
          ...state.skus,
          dailyViewsChart: {
            data,
            dailyViewsSelected,
          },
        },
      };
    }

    //availability chart
    case CHANGE_AVAILABILITY_CHART: {
      const { data } = action.payload;

      return {
        ...state,
        skus: {
          ...state.skus,
          availabilityChart: {
            ...state.skus.availabilityChart,
            data,
          },
        },
      };
    }

    case SORT_AVAILABILITY_CHART: {
      const { availabilitySelected, data } = action.payload;

      return {
        ...state,
        skus: {
          ...state.skus,
          availabilityChart: {
            data,
            availabilitySelected,
          },
        },
      };
    }

    //sales chart
    case CHANGE_DAILY_SALES_CHART: {
      const { data } = action.payload;

      return {
        ...state,
        skus: {
          ...state.skus,
          dailySalesChart: {
            ...state.skus.dailySalesChart,
            data,
          },
        },
      };
    }

    case SORT_DAILY_SALES_CHART: {
      const { dailySalesSelected, data } = action.payload;

      return {
        ...state,
        skus: {
          ...state.skus,
          dailySalesChart: {
            data,
            dailySalesSelected,
          },
        },
      };
    }

    // skus more
    case FETCH_MORE_SKUS_REQUEST: {
      const offset =
        state.currentViewConfig.query.offset +
        state.currentViewConfig.query.limit;
      return {
        ...state,
        skus: {
          ...state.skus,
          loadingMore: true,
          loading: false,
        },
        currentViewConfig: {
          ...state.currentViewConfig,
          query: {
            ...state.currentViewConfig.query,
            limit: insightsSkusLimit,
            offset,
          },
        },
      };
    }

    case FETCH_MORE_SKUS_SUCCESS: {
      const { field, data } = action.payload;

      return {
        ...state,
        skus: {
          ...state.skus,
          [field]: [...state.skus[field], ...data],
          hasMore: data.length !== 0 ? true : false,
          loadingMore: false,
          loading: false,
        },
      };
    }

    case FETCH_MORE_SKUS_FAILURE: {
      return {
        ...state,
        skus: {
          ...state.skus,
          hasMore: false,
          loadingMore: false,
          loading: false,
        },
      };
    }

    case FETCH_MORE_SKUS_CANCEL: {
      return {
        ...state,
        skus: {
          ...state.skus,
          hasMore: true,
          loadingMore: true,
          loading: false,
        },
      };
    }

    //brands
    case FETCH_BRANDS_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        brands: data,
      };
    }

    case FETCH_BRANDS_FAILURE: {
      return {
        ...state,
        brands: [],
      };
    }

    // clear
    case CLEAR_SKUS_ITEMS: {
      let productList = [...state.skus.productList];
      let topCategoryLevel1ProductList = [
        ...state.skus.topCategoryLevel1ProductList,
      ];
      let topCategoryLevel2ProductList = [
        ...state.skus.topCategoryLevel2ProductList,
      ];

      productList = productList.slice(0, insightsSkusLimit);
      topCategoryLevel1ProductList = topCategoryLevel1ProductList.slice(
        0,
        insightsSkusLimit
      );
      topCategoryLevel2ProductList = topCategoryLevel2ProductList.slice(
        0,
        insightsSkusLimit
      );

      return {
        ...state,
        skus: {
          ...state.skus,
          productList,
          topCategoryLevel1ProductList,
          topCategoryLevel2ProductList,
          hasMore: true,
        },
        currentViewConfig: {
          ...state.currentViewConfig,
          query: {
            ...state.currentViewConfig.query,
            limit: insightsSkusLimit,
            offset: 0,
          },
        },
      };
    }

    // clear all
    case CLEAR_INSIGHTS: {
      return {
        ...deepClone(initialState),
      };
    }

    case UPDATE_CURRENT_VIEW_CONFIG_QUERY: {
      const { data } = action.payload;
      const { query } = state.currentViewConfig;

      data.productAttributes = processProductAttributes(
        data.productAttributes,
        state.viewDefinitions
      );

      return {
        ...state,
        insightsAiData: {},
        insightsAiShowSidebar: false,
        currentViewConfig: {
          ...state.currentViewConfig,
          query: {
            ...state.currentViewConfig.query,
            sortField:
              data.sortField !== undefined ? data.sortField : query.sortField,
            sortOrder:
              data.sortOrder !== undefined ? data.sortOrder : query.sortOrder,
            selectedColumns:
              data.selectedColumns !== undefined
                ? data.selectedColumns
                : query.selectedColumns,
            settings:
              data.settings !== undefined ? data.settings : query.settings,
            limit: data.limit !== undefined ? data.limit : query.limit,
            offset: data.offset !== undefined ? data.offset : query.offset,
            queryFrequency:
              data.queryFrequency !== undefined
                ? data.queryFrequency
                : query.queryFrequency,
            productAttributes: data.productAttributes,
            startDate:
              data.startDate !== undefined ? data.startDate : query.startDate,
            endDate: data.endDate !== undefined ? data.endDate : query.endDate,
            previousStartDate:
              data.previousStartDate !== undefined
                ? data.previousStartDate
                : query.previousStartDate,
            previousEndDate:
              data.previousEndDate !== undefined
                ? data.previousEndDate
                : query.previousEndDate,
            mainRootdomain:
              data.mainRootdomain !== undefined
                ? data.mainRootdomain
                : query.mainRootdomain,
          },
        },
      };
    }

    case UPDATE_VIEW_TYPE: {
      const { data } = action.payload;
      return {
        ...state,
        viewTypeData: data,
      };
    }

    case SHOW_SIDEBAR_FILTER: {
      return {
        ...state,
        insightsFilterShowSidebar: action.show,
      };
    }

    case SHOW_INSIGHTS_AI_SIDEBAR: {
      return {
        ...state,
        insightsAiShowSidebar: action.show,
      };
    }

    case UPDATE_FILTERS_SELECTED: {
      return {
        ...state,
        updateFiltersSelected: !state.updateFiltersSelected,
      };
    }

    case CLEAR_FILTERS_SELECTED: {
      return {
        ...state,
        clearFiltersSelected: !state.clearFiltersSelected,
      };
    }
    /** VIEWS CONFIGS */
    case SET_CURRENT_VIEW_IS_UPDATED: {
      return {
        ...state,
        currentViewConfigUpdated: true,
      };
    }
    case SET_INITIAL_VIEW_LOADED: {
      return {
        ...state,
        loadedInitialView: true,
      };
    }
    case SET_CURRENT_VIEW_CONFIG: {
      const { data } = action.payload;
      data.query.productAttributes = processProductAttributes(
        data.query.productAttributes,
        state.viewDefinitions
      );

      return {
        ...state,
        currentViewConfig: data,
        currentViewConfigUpdated: false,
      };
    }

    case UPDATE_CURRENT_VIEW_CONFIG: {
      return {
        ...state,
      };
    }

    case SAVE_INSIGHTS_VIEW_CONFIG_REQUEST: {
      return {
        ...state,
      };
    }
    case SAVE_INSIGHTS_VIEW_CONFIG_FAILURE: {
      return {
        ...state,
      };
    }
    case SAVE_INSIGHTS_VIEW_CONFIG_SUCCESS: {
      const { data } = action.payload;

      const list = [...state.viewsConfigsList, data];
      return {
        ...state,
        viewsConfigsList: list,
        currentViewConfig: data,
        currentViewConfigUpdated: false,
      };
    }
    case SAVE_INSIGHTS_VIEW_CONFIG_CANCEL: {
      return {
        ...state,
      };
    }

    case FETCH_INSIGHTS_VIEWS_CONFIGS_REQUEST: {
      return {
        ...state,
      };
    }
    case FETCH_INSIGHTS_VIEWS_CONFIGS_FAILURE: {
      return {
        ...state,
      };
    }
    case FETCH_INSIGHTS_VIEWS_CONFIGS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        viewsConfigsList: data,
      };
    }
    case FETCH_INSIGHTS_VIEWS_CONFIGS_CANCEL: {
      return {
        ...state,
      };
    }

    case UPDATE_INSIGHTS_VIEW_CONFIG_REQUEST: {
      return {
        ...state,
      };
    }
    case UPDATE_INSIGHTS_VIEW_CONFIG_FAILURE: {
      return {
        ...state,
        currentViewConfig: {
          ...state.currentViewConfig,
          validEmail: true,
        },
      };
    }
    case UPDATE_INSIGHTS_VIEW_CONFIG_SUCCESS: {
      const { data } = action.payload;
      const list = state.viewsConfigsList.filter((item) => {
        return item.name !== data.name;
      });
      return {
        ...state,
        currentViewConfig: {
          ...data,
          lastSync: new Date().getTime(),
        },
        viewsConfigsList: [...list, data],
        currentViewConfigUpdated: false,
      };
    }
    case UPDATE_INSIGHTS_VIEW_CONFIG_CANCEL: {
      return {
        ...state,
      };
    }

    case DELETE_INSIGHTS_VIEW_CONFIG_REQUEST: {
      return {
        ...state,
      };
    }
    case DELETE_INSIGHTS_VIEW_CONFIG_FAILURE: {
      return {
        ...state,
      };
    }
    case DELETE_INSIGHTS_VIEW_CONFIG_SUCCESS: {
      const { data } = action.payload;

      const list = state.viewsConfigsList.filter((item) => {
        return item.id !== data.id;
      });

      return {
        ...state,
        viewsConfigsList: list,
        currentViewConfig: list[0] || defaultViewConfig,
      };
    }
    case DELETE_INSIGHTS_VIEW_CONFIG_CANCEL: {
      return {
        ...state,
      };
    }

    case FETCH_VIEWS_DEFINITIONS_REQUEST: {
      return {
        ...state,
        viewDefinitions: deepClone(initialState.viewDefinitions),
        marketTrendsConfigs: [],
      };
    }
    case FETCH_VIEWS_DEFINITIONS_FAILURE: {
      return {
        ...state,
      };
    }
    case FETCH_VIEWS_DEFINITIONS_SUCCESS: {
      const { data } = action.payload;

      const marketTrendsConfigs = data.marketTrendsConfigs;
      delete data.marketTrendsConfigs;

      const productAttributes = processProductAttributes(
        state.currentViewConfig.query.productAttributes,
        data
      );

      return {
        ...state,
        viewDefinitions: { ...state.viewDefinitions, ...(data ?? {}) },
        currentViewConfig: {
          ...state.currentViewConfig,
          query: {
            ...state.currentViewConfig.query,
            productAttributes: productAttributes,
          },
        },
        marketTrendsConfigs: marketTrendsConfigs,
      };
    }
    case FETCH_VIEWS_DEFINITIONS_CANCEL: {
      return {
        ...state,
        viewDefinitions: {
          ...state.viewDefinitions,
          filterDefinitionsHaveLoaded: false,
        },
      };
    }

    case FETCH_FILTER_DEFINITIONS_REQUEST: {
      return {
        ...state,
        viewDefinitions: {
          ...state.viewDefinitions,
          filterDefinitionsHaveLoaded: false,
        },
      };
    }
    case FETCH_FILTER_DEFINITIONS_FAILURE: {
      return {
        ...state,
        viewDefinitions: {
          ...state.viewDefinitions,
          filterDefinitionsHaveLoaded: false,
        },
      };
    }
    case FETCH_FILTER_DEFINITIONS_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        viewDefinitions: {
          ...state.viewDefinitions,
          filterDefinitions: data,
          filterDefinitionsHaveLoaded: true,
        },
      };
    }
    case FETCH_FILTER_DEFINITIONS_CANCEL: {
      return {
        ...state,
      };
    }

    case EXPORT_INSIGHTS_VIEW_REQUEST: {
      return {
        ...state,
        exportedCsvContent: null,
        isWaitingForExportToFinish: true,
      };
    }
    case EXPORT_INSIGHTS_VIEW_FAILURE: {
      return {
        ...state,
        isWaitingForExportToFinish: false,
        showErrorMessage: true,
      };
    }
    case EXPORT_INSIGHTS_VIEW_FAILURE_CLOSE_MESSAGE: {
      return {
        ...state,
        showErrorMessage: false,
      };
    }
    case EXPORT_INSIGHTS_VIEW_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        exportedCsvContent: data,
        isWaitingForExportToFinish: false,
      };
    }
    case EXPORT_INSIGHTS_VIEW_CANCEL: {
      return {
        ...state,
        isWaitingForExportToFinish: false,
      };
    }
    case REMOVE_EXPORTED_INSIGHTS_VIEW_DATA: {
      return {
        ...state,
        exportedCsvContent: null,
        isWaitingForExportToFinish: false,
      };
    }

    case FETCH_INSIGHTS_AI_REQUEST: {
      const { insightType } = action.payload;
      return {
        ...state,
        insightsAiData: {
          ...state.insightsAiData,
          [insightType]: {
            data: {},
            loading: true,
          },
        },
      };
    }

    case FETCH_INSIGHTS_AI_SUCCESS: {
      const { data, insightType } = action.payload;
      return {
        ...state,
        insightsAiData: {
          ...state.insightsAiData,
          [insightType]: {
            data: data,
            loading: false,
          },
        },
      };
    }

    case FETCH_INSIGHTS_AI_CANCEL: {
      const { insightType } = action.payload;
      return {
        ...state,
        insightsAiData: {
          ...state.insightsAiData,
          [insightType]: {
            ...state.insightsAiData[insightType],
            data: {},
          },
        },
      };
    }

    case FETCH_INSIGHTS_AI_ERROR: {
      const { insightType } = action.payload;
      return {
        ...state,
        insightsAiData: {
          ...state.insightsAiData,
          [insightType]: {
            data: null,
            loading: false,
          },
        },
      };
    }
  }

  return state;
};

const processProductAttributes = (productAttributes, viewDefinitions) => {
  const filterDefinitions = viewDefinitions?.filterDefinitions;
  productAttributes = productAttributes || [];
  if (filterDefinitions.length > 0) {
    // Check product attributes exists in definitions
    productAttributes = productAttributes.filter((productAttribute) => {
      return filterDefinitions.find(
        (filterDefinition) =>
          filterDefinition.mbName === productAttribute.mbName &&
          filterDefinition.type === productAttribute.type &&
          (filterDefinition.displayName ??
            "" === productAttribute.displayName ??
            "")
      );
    });

    // Sort values for enums
    filterDefinitions
      .filter((x) => x.type === "Enum")
      .map((x) => {
        x.enumDictionary = sortObject(x.enumDictionary);
        x.values = x.values.sort();
      });

    // Handle single value constraints
    const selectedByDefaultDefinitions = filterDefinitions.filter(
      (x) => x.constraints?.SelectedByDefault
    );
    if (selectedByDefaultDefinitions.length > 0) {
      selectedByDefaultDefinitions.map((productFilterDefinition) => {
        var productAttribute = productAttributes.find(
          (productAttribute) =>
            productFilterDefinition.mbName === productAttribute.mbName
        );

        // verify filter exists and contains exactly one value, otherwise set it to first value from definitions
        // Pay attention, CURRENTLY ONLY SUPPORTING ENUM PRODUCT ATTRIBUTES!!!
        if (!productAttribute) {
          const value = Object.keys(productFilterDefinition.enumDictionary)[0];
          productAttribute = createFilterValue(
            productFilterDefinition.mbName,
            productFilterDefinition.type,
            [value],
            null,
            null,
            productFilterDefinition.displayName
          );
          productAttributes.push(productAttribute);
        } else {
          if (
            productFilterDefinition.constraints?.SingleValue &&
            productAttribute.values.length !== 1
          ) {
            productAttribute.values = productAttribute.values.slice(0, 1);
          }
        }
      });
    }
  }

  return productAttributes;
};

const insightsReducer = combineReducers({
  insights: insightsBaseReducer,
  mbFilterRow: MbFilterRowReducer,
});

export default insightsReducer;
