export const ROOTDOMAINS = {
  AmazonCom: "amazon.com",
  AmazonCa: "amazon.ca",
  AmazonCoUk: "amazon.co.uk",
  AmazonComMx: "amazon.com.mx",
  AmazonIn: "amazon.in",
  AmazonNl: "amazon.nl",
  AmazonComBr: "amazon.com.br",
  AmazonDe: "amazon.de",
  AmazonAe: "amazon.ae",
  AmazonEs: "amazon.es",
  AmazonFr: "amazon.fr",
  AmazonIt: "amazon.it",
  WayfairCom: "wayfair.com",
  OverstockCom: "overstock.com",
  TargetCom: "target.com",
  LazadaSg: "lazada.sg",
  LazadaVn: "lazada.vn",
  LazadaComMy: "lazada.com.my",
  LazadaCoId: "lazada.co.id",
  ShopeeSg: "shopee.sg",
  ShopeeVn: "shopee.vn",
  ShopeeComMy: "shopee.com.my",
  ShopeeCoId: "shopee.co.id",
  ShopeeCoTh: "shopee.co.th",
  BedbathandbeyondCom: "bedbathandbeyond.com",
  BoutiquerugsCom: "boutiquerugs.com",
  ChewyCom: "chewy.com",
  HomedepotCom: "homedepot.com",
  JoannCom: "joann.com",
  KohlsCom: "kohls.com",
  LowesCom: "lowes.com",
  MichaelsCom: "michaels.com",
  RugsCom: "rugs.com",
  RugsusaCom: "rugsusa.com",
  SayweeeCom: "sayweee.com",
  WalmartCa: "walmart.ca",
  WalmartCom: "walmart.com",
  TokopediaCom: "tokopedia.com",
  MercadolibreComMx: "mercadolibre.com.mx",
};

export const ROOTDOMAINS_ENUM_NAMES = {
  AmazonCom: "AmazonCom",
  AmazonCa: "AmazonCa",
  AmazonCoUk: "AmazonCoUk",
  AmazonComMx: "AmazonComMx",
  AmazonIn: "AmazonIn",
  AmazonNl: "AmazonNl",
  AmazonComBr: "AmazonComBr",
  AmazonDe: "AmazonDe",
  AmazonAe: "AmazonAe",
  AmazonEs: "AmazonEs",
  AmazonFr: "AmazonFr",
  AmazonIt: "AmazonIt",
  WayfairCom: "WayfairCom",
  OverstockCom: "OverstockCom",
  TargetCom: "TargetCom",
  LazadaSg: "LazadaSg",
  LazadaVn: "LazadaVn",
  LazadaComMy: "LazadaComMy",
  LazadaCoId: "LazadaCoId",
  ShopeeSg: "ShopeeSg",
  ShopeeVn: "ShopeeVn",
  ShopeeComMy: "ShopeeComMy",
  ShopeeCoId: "ShopeeCoId",
  ShopeeCoTh: "ShopeeCoTh",
  BedbathandbeyondCom: "BedbathandbeyondCom",
  BoutiquerugsCom: "BoutiquerugsCom",
  ChewyCom: "ChewyCom",
  HomedepotCom: "HomedepotCom",
  JoannCom: "JoannCom",
  KohlsCom: "KohlsCom",
  LowesCom: "LowesCom",
  MichaelsCom: "MichaelsCom",
  RugsCom: "RugsCom",
  RugsusaCom: "RugsusaCom",
  SayweeeCom: "SayweeeCom",
  WalmartCa: "WalmartCa",
  WalmartCom: "WalmartCom",
  TokopediaCom: "TokopediaCom",
  MercadolibreComMx: "MercadolibreComMx",
};

export const NOTIFICATION_FREQUENCY = {
  none: 0,
  hour: 8,
  day: 1,
  week: 2,
  month: 4,
};
