import React, { useEffect, useState } from "react";
import styles from "./insights-export-view.scss";
import {
  DropdownWrapper,
  dropdownTitle,
  dropdownHeader,
  dropdownFooter,
} from "../../../../../components/dropdown";
import InsightsExportsViewDropdown from "./insights-export-view-dropdown";
import ExportButton from "../../../../../components/export-button/export-button";
import { DIMENSIONS } from "../../../../market-share/constants";

const { DropdownHeader } = dropdownHeader;
const { DropdownFooterSingleButton } = dropdownFooter;

const useOpenThroughUrl = () => {
  const [isOpen, setIsOpen] = useState(false);

  const urlListener = () => {
    if (window.location.hash.includes("#export")) {
      setIsOpen(true);
      window.location.hash = "";
      setTimeout(() => setIsOpen(false), 1000);
    }
  };

  useEffect(() => {
    window.addEventListener("hashchange", urlListener);

    return () => {
      window.removeEventListener("hashchange", urlListener);
    };
  }, []);

  return isOpen;
};

const optionsByDimension = (dimension) => {
  const options = [
    { name: 25, value: 25 },
    { name: 50, value: 50 },
    { name: 100, value: 100 },
    { name: 150, value: 150 },
    { name: "1,000", value: 1000 },
  ];
  switch (dimension) {
    case DIMENSIONS.sku:
    case DIMENSIONS.family:
      options.push({ name: "10,000", value: 10000 });
      return options;
    default:
      options.push(
        { name: "10,000", value: 10000 },
        { name: "50,000", value: 50000 }
      );
      return options;
  }
};

function InsightsExportView(props) {
  const isOpen = useOpenThroughUrl();
  const [selectedItem, setSelectedItem] = useState(
    { name: 25, value: 25 } /*props.selectedItem*/
  );

  const handleClickItem = (selectedItem) => {
    setSelectedItem(selectedItem);
  };

  const handleClickButton = () => {
    props.exportInsightsViewHandler(selectedItem.value);
  };
  const dropdownTitle = (
    <ExportButton title={props.title} showSpinner={props.showExportSpinner} />
  );
  const dropdownHeader = <DropdownHeader title={"Export CSV file"} />;

  return (
    <>
      <DropdownWrapper
        dropdownTitle={dropdownTitle}
        dropdownHeader={dropdownHeader}
        dropdownBody={{
          component: InsightsExportsViewDropdown,
          props: {
            selectedItem,
            options: optionsByDimension(props.dimension),
          },
        }}
        dropdownFooter={{
          component: DropdownFooterSingleButton,
          props: { title: "Export" },
          styles,
        }}
        handleClickItem={handleClickItem}
        handleClickButton={handleClickButton}
        wrapDropdownFooter={true}
        styles={styles}
        className="exportView"
        isOpen={isOpen}
      />
    </>
  );
}

export default InsightsExportView;
